:root {
  --footer-offset: 0px;
  --icon-invert-value: 0%;
  --context-menu-marker-left: 0.75rem;
  --context-menu-marker-border-offset: 3px;

  --header-height: 160px;
  /* banner*/
  --banner-height: 133px;
  --banner-mask-color: rgba(165, 154, 134, 0.767);
  /* --banner-background: url("../assets/images/banner6.jpg"); */

  /* sidebar */
  --sidebar-width: 220px;
  --sidebar-highlight-color: rgba(44, 35, 30, 0.7);

  /* reader */
  --reader-width: 55%;
  --reader-left-margin: 2em;
  --reader-left-margin-wo-sidebar: 10em;

  /* ifobar */
  --infobar-width: max(calc(35% - var(--sidebar-width)), 285px);
  --infobar-block-color: rgba(192, 175, 135, 0.164);

  /* footer */
  --footer-height: 195px;
  --footer-background-color: rgba(29, 20, 2, 0.45);
  /* --footer-background-color: rgba(32, 32, 30, 0.75); */
  --footer-offset: 0px;

  --highlight-color: rgb(53, 102, 70);
  --nav-text-color: rgb(19, 19, 17);
  /* --background-color: rgb(179, 171, 134); */
  --background-color: rgb(185, 174, 158);
  --drawing-mask: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.3));
  --margin-from-main: 2.5rem;
  --accent-color: #44285c;
  --post-content-margin: 2rem;
  --login-overlay-width: 40vw;

  --side-width: 0px;
  --scroll-bar-width: 0px;
  --recommended-width: 1440px;

  --back-to-top-button-right: 5rem;
  --back-to-top-button-bottom: 3rem;
  --side-panels-opacity: 0.85;

  --clock-color: rgba(132, 138, 123, 0.76);

  --post-heading-font: "Inter", sans-serif;
}

body {
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  font-weight: 300;
  min-width: 100vw;
  min-height: 110vh;
  overflow-y: scroll;
  /* background-color: rgb(165, 155, 143); */
}

audio::-webkit-media-controls-panel {
  background-color: rgb(63, 52, 63);
}

.not-found-img {
  display: block;
  text-align: center;
  margin: 3rem auto;
  width: 80%;
  filter: grayscale(0.5);
  border-radius: 8px;
}

.svg-animation-container {
  width: 0;
  height: 0;
}

.back-to-top-button {
  flex-direction: column;
  display: none;
  position: fixed;
  bottom: var(--back-to-top-button-bottom);
  right: calc(var(--side-width) + var(--back-to-top-button-right));
  margin: 0.5em 0;
  border-radius: 50%;
  background: linear-gradient(
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0.6),
    rgba(0, 0, 0, 0.35)
  );
  border: none;
  outline: none;
  height: 60px;
  width: 60px;
  align-items: center;
  z-index: 21;
}

.back-to-top-button > img {
  margin-top: 2px;
}

.back-to-top-button:hover {
  box-shadow: -1px -2px 1px rgba(248, 248, 242, 0.85);
  cursor: pointer;
}

.back-to-top-button:hover img {
  transform: translate(0px, -3px);
}

.back-to-top-img {
  --icon-dimentions: 3.25rem;
  width: var(--icon-dimentions);
  height: var(--icon-dimentions);
}

/* load spinner */

.load-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  font-family: Inter, sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 6px;
  color: var(--accent-color);

  backdrop-filter: blur(4px);
  background-color: rgba(0, 0, 0, 0.09);
  /* border-radius: var(--overlay-3D-border-radius); */
}

.load-spinner-graph {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.load-spinner h2 {
  text-align: center;
  font-size: 16px;
}

/* ************************************************* */
/* main section */
/* ************************************************* */
.social {
  font-family: Inter, sans-serif;
  display: grid;
  grid-template-columns: repeat(3, 81px);
  gap: 2px;
  justify-content: flex-start;
  margin-left: var(--post-content-margin);
  margin-top: 1.75rem;
  filter: sepia(0.9);
}
.social:hover {
  filter: sepia(0.1);
  transition: 200ms;
}

.main-container {
  display: flex;
  width: 100%;
  margin: 0px;
  margin-top: var(--margin-from-main);
}
.blur-background {
  filter: blur(0px);
}

.icon-sidebar-item {
  --icon-dimentions: 1.25rem;
  vertical-align: middle;
  width: var(--icon-dimentions);
  height: var(--icon-dimentions);
}

.elem-visible-hidden {
  visibility: hidden;
}

.elem-visible-show {
  visibility: visible;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
  .banner-buttons,
  .collection-buttons,
  .icon-sidebar-item,
  .back-to-top-img,
  .footer-social-button,
  .drawing-img {
    image-rendering: -webkit-optimize-contrast !important;
  }
}
/* Unset for Safari 11+ */
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    .banner-buttons,
    .collection-buttons,
    .icon-sidebar-item,
    .back-to-top-img,
    .footer-social-button,
    .drawing-img {
      image-rendering: unset !important;
    }
  }
}

.sidebar {
  font-family: "Inter", sans-serif;
  font-weight: bold;
  font-size: 0.75rem;
  filter: opacity(var(--side-panels-opacity));
  transition: filter 300ms;
}

/* .sidebar-filler {
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(36, 24, 2, 0) 36px,
    rgba(242, 245, 226, 0.5) 39px,
    rgba(242, 245, 226, 0.5) 130px,
    rgba(0, 0, 0, 0) 131px
  );
  height: 100%;
  width: 150px;
} */

.sidebar:hover {
  filter: none;
}

.side-nav {
  height: 100%;
  width: var(--sidebar-width);
  display: flex;
  flex-direction: column;
}

.side-nav .close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 2em;
  outline: none;
  border: none;
  background: transparent;
  display: none;
  color: beige;
}

/* sidebar links */
.sidebar a {
  font-weight: 500;
  color: rgb(32, 23, 12);
  display: block;
  padding: 0.6em;
  text-decoration: none;
  border-top-right-radius: 0.725em;
  border-bottom-right-radius: 0.725em;
  border-left: rgba(0, 0, 0, 0) 3px solid;
  text-transform: uppercase;
  letter-spacing: 0.15em;
}

.sidebar a:hover {
  --icon-invert-value: 90%;
}

.sidebar a:hover:not(.active) {
  color: blanchedalmond;
  background-color: var(--highlight-color);
  border-left: var(--sidebar-highlight-color) 3px solid;
  transition: background 300ms;
}

.sidebar a:hover:not(.active) i {
  color: blanchedalmond;
}

.sidebar .active {
  --icon-invert-value: 90%;
  background-color: black;
  background-color: var(--sidebar-highlight-color);
  color: blanchedalmond;
  border-left: var(--highlight-color) 3px solid;
}
/* .sidebar i {
  width: 1.5rem;
} */
.sidebar-menu-item {
  /* width: 1.5rem; */
  filter: invert(var(--icon-invert-value));
  margin-right: 0.8rem;
}

/* .sidebar i::after {
  content: "";
  padding-right: 0.8rem;
} */

.sidebar a:not(.active) i:not(.sidebar-new-content) {
  color: var(--accent-color);
}

.sidebar-new-content {
  position: relative;
  /* color: rgba(0, 0, 0, 0.5); */
  font-size: 1.2em;
  width: 1rem;
}
/* .sidebar .active .sidebar-new-content {
  color: rgba(255, 235, 205, 0.75);
  filter: invert(var(--icon-invert-value));
} */

.sidebar-new-content:hover::after {
  position: absolute;
  content: "Content updated within previous fortnight";
  width: 14em;
  line-height: 1.25em;
  text-align: center;
  text-transform: none;
  font-weight: 600;
  top: -3.75em;
  left: 1em;
  padding: 0.75em;
  z-index: 2;
  color: beige;
  border-radius: 0.5em;
  border-bottom: 2px solid beige;
  border-right: 2px solid beige;
  background: rgba(0, 0, 0, 0.8);
  filter: none;
}

.context-menu {
  margin-top: 5em;
  margin-left: 1.25em;
  margin-bottom: 2em;
  position: -webkit-sticky;
  position: sticky;
  top: 9rem;
  /* overflow is not compatible with sticky */
}

.context-menu-collapsed,.filter-panel-collapsed {
  height: 2em;
  overflow-y: hidden;
}

.context-menu i,.filter-panel i {
  margin-left: 1em;
}

.context-menu h3,.filter-panel h3 {
  color: var(--accent-color);
  margin-bottom: 0.5em;
  text-transform: uppercase;
  cursor: pointer;
}

.context-menu a {
  padding: 0.25em 0.5em;
  margin-top: 0.75em;
  margin-left: 1em;
}

.context-menu .left-marker-beige {
  border-left: 1px solid beige;
  padding-left: calc(
    var(--context-menu-marker-left) + var(--context-menu-marker-border-offset)
  );
}

.context-menu .left-marker-accent {
  border-left: 1px solid maroon;
  padding-left: calc(
    var(--context-menu-marker-left) + var(--context-menu-marker-border-offset)
  );
}

.context-menu .left-marker-active {
  border-left: calc(var(--context-menu-marker-border-offset) + 1px) solid beige;
  padding-left: var(--context-menu-marker-left);
}

.context-menu a::first-letter {
  font-size: 1.25em;
  font-weight: 700;
  color: var(--accent-color);
}

.context-menu a:hover {
  color: black !important;
  background-color: transparent !important;
  border-left: none !important;
  border-bottom: 1px solid maroon;
  margin-bottom: -1px;
  border-radius: 0;
  padding-left: calc(var(--context-menu-marker-left) + 5px);
}

.filter-panel {
  margin-top: 6em;
  margin-left: 1.25em;
  margin-bottom: 1em;
  position: -webkit-sticky;
  position: sticky;
  top: 3rem;
  /* overflow is not compatible with sticky */
}

.filter-tag{
  display: inline-block;
  margin-top: 1rem;
  margin-left: 1.75rem;
  cursor: pointer;
}

.filter-tag:hover::after{
content: "\0353" !important;
/* padding: 0 2px; */
/* margin-left: 8px; */
/* color: white; */
/* font-size: 24px; */
font-weight:100 ;
  /* top: -32px;
  right: -20px; */
}

.menu-overlay {
  position: fixed;
  /* top: calc(var(--header-height)); */
  /* top: calc(var(--header-height) - var(--pageY-current-offset)); */
  top: var(--header-height);
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0.04),
    rgba(0, 0, 0, 0)
  );
  z-index: 2;
}

/* **************** */
/* reader */
/* **************** */

.reader {
  border: 1px solid maroon;
  box-shadow: 1px 1px 10px darkgray;
  width: var(--reader-width);
  padding: 0em 2em;
  padding-bottom: 1em;
  border-radius: 1em;
  border-top-right-radius: 3rem;
  margin: 0em;
  min-height: calc(100vh - var(--header-height) - var(--footer-height));
  margin-bottom: 8em;
  margin-left: var(--reader-left-margin);
}
.chapter-name {
  font-family: "Inter", sans-serif;
  margin: 0.7em 0;
  color: rgb(43, 9, 88);
  text-align: center;
  text-transform: uppercase;
}

.chapter-unpublished {
  background: rgba(175, 172, 172, 0.767);
}
.reader-hr {
  border: 1px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(
    to right,
    rgba(177, 172, 158, 0.2),
    rgba(53, 102, 70, 1),
    rgba(177, 172, 158, 0.2)
  );
  border-radius: 2px;
  width: 10%;
  margin-top: 2.5em;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2.5em;
}

.reader h3 {
  font-family: "Inter", sans-serif;
  color: var(--accent-color);
  margin: 1em 1em;
  text-align: left;
}
.reader h4 {
  margin-left: 3em;
}
.reader address {
  margin: 1em 1em;
}
.chapter-text {
  font-family: "Texturina", serif;
  text-align: justify;
  text-justify: inter-word;
}
.chapter-text-paragraph {
  text-indent: 1.125em;
}
.chapter-text-paragraph::first-letter {
  font-size: 1.5rem;
  font-weight: bolder;
}
.picture-frame {
  display: grid;
  /* border: 1px solid black; */
}
.picture:hover,
.picture-slide:hover {
  cursor: zoom-in;
}
.picture {
  /* float: left; */
  justify-self: center;
  width: 12rem;
  border-radius: 0.35em;
  border: 1px solid rgb(117, 101, 80);
  /* padding-right: 0.7125em;
  padding-top: 1.125em; */
  padding: 0.125em;
  margin: 1em;
}
.picture-slide {
  justify-self: center;
}
.picture-left {
  float: left;
  margin-left: 0;
}
.picture-right {
  float: right;
  margin-right: 0;
}

.sticky-embelishment {
  position: -webkit-sticky;
  position: sticky;
  bottom: var(--footer-offset);
  align-self: flex-start;
  margin-top: -14em;
  pointer-events: none;
  z-index: -1;
}

.item-loading {
  color: beige;
  /* line-height: 50vh; */
  animation: item-rotate 2.5s linear infinite;
}

@keyframes item-rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.twitter-button {
  background: #1da1f2;
  color: white;
  font-size: 11px;
  font-weight: 400;
  text-decoration: none;
  text-align: center;
  padding: 3px 0;
  border-radius: 3px;
  min-width: 81px;
  width: auto;
  height: 20px;
}

.twitter-button > i {
  margin-right: 0.5em;
}

/* footer */

.footer {
  /* width: 100vw; */
  height: var(--footer-height);
  text-align: center;
  /* background: var(--background-footer-color); */
  background: linear-gradient(
      to bottom,
      var(--footer-background-color),
      var(--footer-background-color)
    ),
    url("../assets/images/shanty-town-029_l.webp"),
    50% url("../assets/images/shanty-town-006.webp"),
    100% url("../assets/images/shanty-town-029.webp");
  background-repeat: no-repeat;
  background-size: contain;
  /* background-position: 100%; */
  color: black;
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 360px) and (max-width: 810px) and (orientation: landscape) {
  .sidebar {
    width: 0;
  }
  .sidebar-new-content:hover::after {
    position: fixed;
    left: 0.5em;
    top: 1em;

    width: 30%;
    max-width: 180px;
    line-height: 1em;
    font-weight: 400;
  }
  .side-nav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 22;
    top: 0;
    left: 0;
    background-color: rgba(27, 27, 26, 0.92);
    overflow-x: hidden;
    padding-top: 60px;
    transition: 500ms;
  }
  .side-nav a {
    color: rgb(240, 234, 228);
    transition: 300ms;
  }
  .side-nav a:hover:not(.active) {
    color: blanchedalmond;
    transition: background 300ms;
  }
  .side-nav .active {
    background-color: rgb(124, 121, 121);
  }
  .side-nav .close-button {
    display: block;
    position: absolute;
    /* top: -25px;
    right: -10px; */
    font-size: 2.5em;
    transition: none;
  }
  .context-menu {
    position: unset;
  }
  .context-menu a::first-letter {
    color: rgb(124, 121, 121);
  }
  .context-menu a:hover {
    color: beige !important;
  }
  .close-button:hover,
  .close-button:focus {
    background-color: transparent !important;
    border: none !important ;
    color: rgb(124, 121, 121) !important;
  }
  .side-nav a:hover:is(.close-button),
  .side-nav a:focus:is(.close-button) {
    background-color: transparent;
    border: none;
    color: rgb(124, 121, 121);
  }
  .main-container {
    flex-direction: column;
    align-items: center;
  }
  .reader {
    --reader-width: 94%;
    margin: 0 auto;
    margin-bottom: 8em;
  }
  .reader img {
    float: left;
    width: 7rem;
  }

  .sticky-embelishment {
    display: none;
  }
}

@media screen and (min-width: 811px) and (max-width: 1200px) and (orientation: landscape) {
  .reader {
    --reader-width: calc(
      100% - var(--infobar-width)
        /* var(--reader-width) + 20rem
        + var(--sidebar-width) - 3em + var(--infobar-width) */
    );
    margin-left: var(--reader-left-margin);
    margin-right: 2em;
  }
  .sticky-embelishment {
    display: none;
  }
}

@media screen and (min-width: 200px) and (max-width: 315px) and (orientation: portrait) {
  :root {
    --footer-height: 250px;
  }

  .picture {
    width: 10rem;
    margin: 1em;
  }
}

@media screen and (min-width: 200px) and (max-width: 340px) and (orientation: portrait) {
  /* reader */
  .picture {
    width: 10rem;
    margin: 1em;
  }
  .reader {
    --reader-left-margin-wo-sidebar: 0;
  }

  .footer {
    background: linear-gradient(
        to right,
        var(--footer-background-color),
        var(--footer-background-color)
      ),
      url("../assets/images/shanty-town-029-center.webp");
    background-repeat: no-repeat;
    background-size: cover;
  }
}

@media screen and (min-width: 341px) and (max-width: 1024px) and (orientation: portrait) {
  :root {
    --reader-width: 94%;
  }
  .picture {
    margin: 1em;
  }

  .footer {
    background: linear-gradient(
        to right,
        var(--footer-background-color),
        var(--footer-background-color)
      ),
      url("../assets/images/shanty-town-029_l.webp"),
      100% url("../assets/images/shanty-town-029.webp");
    background-repeat: no-repeat;
    background-size: contain;
  }
}

@media screen and (orientation: portrait) {
  .sidebar {
    width: 0%;
    font-size: 1rem;
    filter: none;
  }
  .sidebar-new-content:hover::after {
    position: fixed;
    left: 0.5em;
    top: 1em;

    width: 65%;
    line-height: 1em;
    font-weight: 400;
  }
  .side-nav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 22;
    top: 0;
    left: 0;
    background-color: rgba(27, 27, 26, 0.92);
    overflow-x: hidden;
    padding-top: 60px;
    transition: 500ms;
  }
  .side-nav a {
    color: rgb(240, 234, 228);
    transition: 300ms;
  }
  .side-nav a:hover:not(.active) {
    color: blanchedalmond;
    transition: background 300ms;
  }
  .side-nav .active {
    background-color: rgb(124, 121, 121);
    /* color: blanchedalmond; */
  }
  .side-nav .close-button {
    display: block;
    position: absolute;
    /* top: -25px;
    right: -10px; */
    font-size: 2.5em;
    transition: none;
  }
  .context-menu {
    position: unset;
  }
  .context-menu a::first-letter {
    color: rgb(124, 121, 121);
  }
  .context-menu a:hover {
    color: beige !important;
  }
  .close-button:hover,
  .close-button:focus {
    background-color: transparent !important;
    border: none !important ;
    color: rgb(124, 121, 121) !important;
  }
  .side-nav a:hover:is(.close-button),
  .side-nav a:focus:is(.close-button) {
    background-color: transparent;
    border: none;
    color: rgb(124, 121, 121);
  }

  .main-container {
    flex-direction: column;
    align-items: center;
  }

  .toggle-side-nav-button {
    margin-bottom: 0.2em;
  }

  .reader {
    --reader-width: 92%;
    margin-left: 0;
    border-top-right-radius: 1em;
  }
  .chapter-text {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .picture-frame {
    clear: both;
    float: none;
    justify-items: center;
  }
  .sticky-embelishment {
    display: none;
  }
}
