/* @import url("https://fonts.googleapis.com/css2?family=Play:wght@400;700&family=Merienda&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Caveat:wght@400;500;600;700&display=swap");
/* @import url("https://fonts.googleapis.com/css2?family=Advent+Pro:wght@300&family=Audiowide&family=Cabin&family=Faustina:wght@300&family=Kalam:wght@300;400&family=M+PLUS+Rounded+1c&family=Michroma&family=Tillana&display=swap"); */

@import url("https://fonts.googleapis.com/css2?family=Codystar&family=Tillana&display=swap");
/* @import url("https://fonts.googleapis.com/css2?family=Nova+Script&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Cagliostro&display=swap"); */

body {
  --overlay-3D-offset: 58px;
  --overlay-3D-put-behind: none;
  --overlay-3D-bookmark-width: 80px;
  --hotspot-hover-width: 350px;
  --hotspot-hover-height: 125px;
  --hotspot-hover-left: -70px;
  --hotspot-hover-top: -150px;
  --hotspot-hover-bottom: 90px;
  --hotspot-hover-img-height: 108px;
  --hotspot-hover-img-offset: 8px;
  --hotspot-ref-hover-angle: 0deg;
}

.sides-not-present,
sides-present {
  background-repeat: no-repeat;
  background-size: cover;
}

.overlay-3D {
  --overlay-3D-padding: 2px;
  --overlay-3D-border-radius: 26px;
  --overlay-3D-color: linear-gradient(
    to bottom,
    rgb(244, 244, 230) 80%,
    rgb(203, 202, 192)
  );
  --overlay-3D-bookmark-height: 109%;
  --overlay-3D-menu-perspective: none;

  position: fixed;
  top: var(--overlay-3D-offset);
  left: var(--overlay-3D-offset);
  bottom: var(--overlay-3D-offset);
  right: var(--overlay-3D-offset);

  background: var(--overlay-3D-color);
  z-index: 10;
  border-radius: var(--overlay-3D-border-radius);
  /* box-shadow: 2px 5px 10px gray; */
  /* backdrop-filter: blur(10px); */
  -webkit-user-select: none; /* turn off text selection  / Webkit */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE 10  */
  user-select: none;
}

.overlay-3D:focus {
  outline: none;
}

.overlay-3D[fullscreen="1"] {
  --overlay-3D-offset: 0px;
  --overlay-3D-border-radius: 0px;
}

.overlay-3D[fullscreen="1"] .overlay-3D-bookmark-shadow,
.overlay-3D[fullscreen="1"] .overlay-3D-bookmark {
  visibility: hidden;
}

.overlay-3D[fullscreen="1"] .overlay-3D-gyro {
  left: 15px;
}

.overlay-3D[postervisible="1"] {
  --overlay-3D-padding: 0;
}

/* ---DARK THEME--- */
.overlay-3D[theme="dark"],
.overlay-3D[theme="dark"] .overlay-3D-poster {
  background-image: linear-gradient(
    to top,
    rgb(17, 12, 12) 70%,
    rgb(97, 86, 86)
  );
}

.overlay-3D[theme="dark"] .overlay-3D-caption {
  color: beige;
  text-shadow: 1px 1px 3px var(--accent-color);
}

.overlay-3D[theme="dark"] .overlay-3D-info {
  color: rgb(245, 220, 245);
}

/* .overlay-3D[theme="dark"] .overlay-3D-poster {
    background-color: rgb(27, 22, 22);
} */

.overlay-3D[theme="dark"] .overlay-3D-model-menu-item span {
  /* color: var(--accent-color); */
  color: rgb(231, 115, 115);
  text-shadow: none;
  font-size: 24px;
}

.overlay-3D[theme="dark"] .overlay-3D-model-menu-item span:hover {
  color: rgb(245, 240, 240);
}

.overlay-3D[theme="dark"] .overlay-3D-caption-toggle-sign {
  filter: opacity(0.4);
}

.overlay-3D[theme="dark"] .overlay-3D-info-btn-close {
  color: beige;
}

.overlay-3D[theme="dark"] .overlay-3D-info-btn-close:hover {
  color: white;
}

.overlay-3D[theme="dark"] .overlay-3D-model-menu-item:hover {
  background-color: rgba(245, 245, 220, 0.1);
}

.overlay-3D[theme="dark"] .model-hotspot-ref-hover {
  --model-hotspot-ref-hover-border-col: rgb(212, 203, 213);
  border-bottom: 2px solid var(--model-hotspot-ref-hover-border-col);
  background: rgba(245, 245, 220, 0.9);
  color: var(--accent-color);
}

/* .overlay-3D[theme="dark"] .model-hotspot-ref-hover span {
  position: relative;
} */

.overlay-3D[theme="dark"] .model-hotspot-ref-hover span:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    rgba(245, 245, 220, 0) 90px,
    rgba(245, 245, 220, 0.8) 108px,
    rgba(245, 245, 220, 0.9)
  );
  border-radius: 5px;
}

.overlay-3D[theme="dark"] .model-hotspot-ref-hover[border-right] {
  border-right: 2px solid var(--model-hotspot-ref-hover-border-col);
}

.overlay-3D[theme="dark"] .model-hotspot-ref-hover[border-left] {
  border-left: 2px solid var(--model-hotspot-ref-hover-border-col);
}

.overlay-3D[theme="dark"] .overlay-3D-animated-background {
  display: none;
}

.overlay-3D[theme="dark"] .overlay-3D-buttons-panel {
  opacity: 0.3;
}

.overlay-3D[theme="dark"] .overlay-3D-buttons-panel:hover {
  opacity: 1;
}

.overlay-3D-gyro {
  --overlay-3D-gyro-size: 120px;
  position: absolute;
  top: 15px;
  left: 95px;
  width: var(--overlay-3D-gyro-size);
  height: var(--overlay-3D-gyro-size);
  /* background: rgba(0, 0, 0, 0.01); */
  border-radius: 50%;
  background: radial-gradient(
    farthest-corner at 63px 62px,
    transparent 50%,
    rgba(0, 0, 0, 0.07) 70%,
    rgba(0, 0, 0, 0.03)
  );
  z-index: 4;
  /* opacity: var(--overlay-3D-put-behind); */
  opacity: 0.2;
  /* transform: translateZ(1px); */
  transition: opacity 350ms;
}

.overlay-3D-gyro[bringup="1"] {
  opacity: min(var(--overlay-3D-put-behind), 1);
}

.overlay-3D-gyro:hover {
  opacity: min(var(--overlay-3D-put-behind), 1);
}

.overlay-3D-gyro > canvas {
  width: var(--overlay-3D-gyro-size);
  height: var(--overlay-3D-gyro-size);
}

.overlay-3D-canvas {
  --overlay-3D-canvas-width: 135px;
  --overlay-3D-canvas-height: 120px;

  position: absolute;
  bottom: 8px;
  right: 8px;
  width: var(--overlay-3D-canvas-width);
  height: var(--overlay-3D-canas-height);
  pointer-events: auto;
  /* background-color: rgba(255, 0, 0, 0.136); */
}

.overlay-3D-canvas:hover:after {
  content: "Pick random";
  position: absolute;
  right: -16px;
  bottom: -16px;
  font-family: Inter, sans-serif;
  width: max-content;
  line-height: 1.25em;
  text-align: center;
  text-transform: none;
  font-weight: 600;
  font-size: 16px;
  padding: 0.35em 0.75em;
  z-index: 2;
  color: beige;
  border-radius: 0.5em;
  border-bottom: 2px solid beige;
  border-right: 2px solid beige;
  background: rgba(0, 0, 0, 0.8);
}

.overlay-3D-bookmark-shadow {
  position: absolute;
  top: -2px;
  left: 1px;
  font-family: "Inter", sans-serif;
  width: var(--overlay-3D-bookmark-width);
  height: var(--overlay-3D-bookmark-height);
  background-color: rgba(0, 0, 0, 0.217);
  border-top-left-radius: var(--overlay-3D-border-radius);
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 51% 93%, 0% 100%);
  z-index: 1;
}

.overlay-3D-bookmark {
  --strips-color: rgba(70, 14, 70, 0.817);
  --band-color: rgba(240, 238, 219, 0.97);
  position: absolute;
  display: flex;
  top: -3px;
  left: 0px;
  width: var(--overlay-3D-bookmark-width);
  height: var(--overlay-3D-bookmark-height);
  min-height: 450px;
  background-image: linear-gradient(
    to right,
    var(--band-color) 65%,
    var(--strips-color) 65%,
    var(--strips-color) 73%,
    var(--band-color) 73%,
    var(--band-color) 80%,
    var(--strips-color) 80%,
    var(--strips-color) 88%,
    var(--band-color) 88%
  );
  border-top-left-radius: var(--overlay-3D-border-radius);
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 51% 93%, 0% 100%);

  font-size: 24px;
  text-transform: uppercase;
  font-family: "Inter", sans-serif;
  font-family: "Codystar", cursive;
  font-weight: 400;
  writing-mode: vertical-lr;
  text-orientation: upright;
  text-align: start;
  color: var(--accent-color);
  z-index: 2;
}

.overlay-3D-menu-icon {
  position: relative;
  font-size: 24px;
  width: 52px;
  height: 72px;
  text-align: center;
  writing-mode: horizontal-tb;
  margin-top: 12px;
  margin-left: 3px;
}

.overlay-3D-bookmark-item {
  --overlay-3D-padding-top: 0px;
  position: relative;
  width: 52px;
}

.overlay-3D-bookmark-item:first-of-type {
  --overlay-3D-padding-top: 28px;
}

.overlay-3D-sidemenu-icon {
  position: absolute;
  top: -28px;
  right: 6px;
  width: 32px;
  height: 32px;
}

.overlay-3D-sidemenu-icon-dim {
  filter: opacity(0.15);
}

.overlay-3D-sidemenu-icon-dim:first-of-type {
  margin-top: 68px;
}

.overlay-3D-sidemenu-icon-dim:hover {
  filter: none;
}

.overlay-3D-sidemenu-icon:hover {
  cursor: pointer;
}

.overlay-3D-sidemenu-caption {
  display: block;
  text-decoration: none;
  padding-top: var(--overlay-3D-padding-top);
  padding-bottom: 28px;
  margin-left: 14px;
  color: var(--accent-color);
  height: max-content;
  border-top-left-radius: var(--overlay-3D-border-radius);
  clip-path: polygon(0% 8%, 40% 2%, 100% 0%, 100% 80%, 50% 84%, 0% 88%);
}

.overlay-3D-sidemenu-caption:hover {
  text-shadow: 1px 1px 2px var(--accent-color);
  /* background-color: red; */
  cursor: pointer;
}

.overlay-3D-sidemenu-item {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  pointer-events: none;
}

.overlay-3D model-viewer {
  /* --poster-color: transparent; */
  --poster-color: var(--overlay-3D-color);
  --progress-bar-color: transparent;

  width: 100%;
  height: 100%;
  border-radius: var(--overlay-3D-border-radius);
  padding: var(--overlay-3D-padding);
}

.overlay-3D-animated-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.overlay-3D-btn-close {
  position: absolute;
  color: maroon;
  top: -65px;
  right: -35px;
  font-size: 52px;
  filter: opacity(0.55);
  text-shadow: 1px 1px 2px white;
  width: 60px;
  height: 60px;
  text-align: center;
  vertical-align: middle;
  border-radius: 50%;
  line-height: 60px;
}

.overlay-3D-btn-close:hover {
  background-color: beige;
  background-clip: text;
  text-shadow: none;
  color: transparent;
  filter: none;
  cursor: pointer;
}

.overlay-3D-progress-bar {
  --mv-progress-bar-width: 260px;
  --mv-progress-bar-height: 3.75rem;

  width: 100%;
  height: 100%;
  text-align: center;
  backdrop-filter: blur(2px);
}

.overlay-3D-update-bar {
  position: absolute;
  color: beige;
  width: var(--mv-progress-bar-width);
  top: calc(50% - var(--mv-progress-bar-height) / 2);
  left: calc(50% - var(--mv-progress-bar-width) / 2);
  font-size: 2.5rem;
  text-shadow: 1px 1px 2px black;
}

.overlay-3D-update-bar-percent {
  position: absolute;
  bottom: 1.25rem;
  left: calc(1.35rem + var(--mv-progress-bar-width) / 2);
  font-size: 1.25rem;
}

.overlay-3D-progress-bar-background {
  position: absolute;
  display: flex;
  align-items: center;
  top: calc(50% - var(--mv-progress-bar-height) / 2 + 6.25rem);
  left: calc(50% - var(--mv-progress-bar-width) / 2);
  background-color: rgba(255, 255, 255, 0.7);
  height: 4px;
  border-radius: 16px;
  width: var(--mv-progress-bar-width);
}

.overlay-3D-progress-bar-foreground {
  width: 0px;
  height: 2px;
  margin: 0 5px;
  background-color: var(--accent-color);
}

.overlay-3D-poster {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: scale-down;
  background: var(--overlay-3D-color);
  border-radius: var(--overlay-3D-border-radius);
}

.overlay-3D-caption {
  position: absolute;
  top: 38px;
  right: 60px;
  font-family: "Caveat", sans-serif;
  font-size: 24px;
  letter-spacing: 3px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 5px 15px;
  border-radius: 15px;
  backdrop-filter: blur(4px);
  cursor: pointer;
  text-align: right;
  opacity: var(--overlay-3D-put-behind);
  transform: var(--overlay-3D-menu-perspective);

  background: linear-gradient(
    to bottom,
    var(--accent-color) 38%,
    rgb(240, 217, 239)
  );

  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;

  /* transition: transform 300ms; */
}

.overlay-3D-caption:hover {
  color: var(--accent-color);
  text-shadow: 1px 1px 2px beige;
}

/* .overlay-3D-caption[do-perspective="1"]:hover {
  transform: perspective(950px) translateZ(24px) rotateY(-3deg);
} */

.overlay-3D-caption-toggle-sign {
  position: absolute;
  top: 14px;
  right: -16px;
  filter: opacity(0.2);
  color: var(--accent-color);
}

.overlay-3D-caption-toggle-sign-up {
  top: -6px;
}

.overlay-3D-caption-items-num {
  position: absolute;
  top: 3px;
  right: -36px;
  width: 18px;
  height: 18px;
  font-family: "Inter", sans-serif;
  font-size: 10px;
  letter-spacing: 0px;
  font-weight: 400;
  line-height: 18px;
  border-radius: 50%;
  text-align: center;
  background-color: rgba(8, 0, 255, 0.333);
  color: white;
  text-shadow: none;
  /* border: 1px solid red; */
}

.overlay-3D-caption-items-num[updatedfortnight="true"] {
  background-color: rgba(255, 0, 0, 0.333);
}

.overlay-3D-buttons-panel {
  position: absolute;
  right: 0px;
  top: calc((100vh - var(--overlay-3D-offset) * 2) / 2 - 100px);
  width: 50px;
  height: 200px;
  padding: 5px;
  opacity: 0.1;
}

.overlay-3D-buttons-panel:hover {
  opacity: 1;
}

.overlay-3D-buttons-panel-btn {
  position: relative;
  width: 42px;
  height: 42px;
  outline: none;
  border: none;
  margin: 2px 0px;
  color: beige;
  background-color: var(--accent-color);
  /* background-color: darkgray; */
  border-radius: 6px;
  cursor: pointer;
}

.overlay-3D-buttons-panel-btn:hover::after {
  content: attr(data-tooltip);
  position: absolute;
  top: -0.5em;
  left: -200px;
  font-family: Inter, sans-serif;
  width: max-content;
  max-width: 190px;
  line-height: 1.25em;
  text-align: center;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 16px;
  padding: 1em 1em;
  z-index: 2;
  color: beige;
  border-radius: 0.5em;
  border-bottom: 2px solid beige;
  border-right: 2px solid beige;
  background: rgba(0, 0, 0, 0.8);
  visibility: hidden;
  animation: revealtooltip 3000ms linear 1000ms;
}

@keyframes revealtooltip {
  0% {
    visibility: visible;
    opacity: 0;
  }
  8% {
    opacity: 1;
  }
  92% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}
/* @keyframes revealtooltip {
  0% {
    visibility: visible;
  }
  100% {
    visibility: hidden;
  }
} */

.overlay-3D-buttons-panel-btn[toggled-off="true"] {
  border: 4px double beige;
}

.overlay-3D-buttons-panel-btn img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.overlay-3D-models-menu {
  padding: 12px 0;
  margin-top: 12px;
  font-family: Lora, serif;

  /* font-family: "Roboto Serif", serif; */
  font-size: 28px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 1px;
  text-shadow: 1px 1px 2px var(--accent-color);
  text-transform: none;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  border-top: 7px double maroon;
  min-width: 270px;
}

.overlay-3D-model-menu-item {
  --overlay-3D-show-model-preview: none;
  padding: 8px 12px;
  color: beige;
  cursor: pointer;
}

.overlay-3D-model-menu-item:hover {
  --overlay-3D-show-model-preview: initial;
}

.overlay-3D-model-menu-item-hover {
  --model-hover-padding: 10px;
  --model-hover-height: 360px;

  display: var(--overlay-3D-show-model-preview);
  position: absolute;
  bottom: 20px;
  left: calc(-1 * var(--model-hover-height) * 1.333 - 80px);
  height: var(--model-hover-height);
  backdrop-filter: blur(3px);

  background: linear-gradient(
    to bottom,
    rgba(141, 141, 141, 0.9) 8px,
    rgba(102, 101, 101, 0.8) 16px,
    rgba(0, 0, 0, 0.75) 32px,
    rgba(0, 0, 0, 0.73)
  );
  box-shadow: 4px 4px 20px -2px rgba(0, 0, 0, 0.8);
  border-radius: 26px;
  padding: var(--model-hover-padding);
  border-top: 1px solid beige;
  border-left: 3px solid beige;
  animation: model_preview_fadein 400ms ease-in 1,
    gentle-move-model-preview 9000ms linear infinite;
}

@keyframes model_preview_fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes gentle-move-model-preview {
  0% {
    transform: perspective(250px) translateZ(-10px) rotateY(8deg);
  }
  30% {
    transform: perspective(230px) translateZ(-10px) rotateY(5deg);
  }
  60% {
    transform: perspective(260px) translateZ(-10px) rotateY(9deg);
  }
  100% {
    transform: perspective(255px) translateZ(-10px) rotateY(8deg);
  }
}

.overlay-3D-model-menu-item-hover img {
  --model-hover-img-height: calc(
    var(--model-hover-height) - 2 * var(--model-hover-padding)
  );

  height: var(--model-hover-img-height);
  width: calc(var(--model-hover-img-height) * 1.333);
  object-fit: contain;
}

.overlay-3D-model-menu-item-hover:after {
  content: "annotations: " attr(data-annotations);
  position: absolute;
  top: 0;
  right: 32px;
  font-family: "Caveat", sans-serif;
  font-size: 32px;
}

.overlay-3D-model-menu-item span {
  position: relative;
  margin-right: 6px;
  /* color: var(--accent-color); */
  color: rgba(84, 1, 1, 0.593);
  text-shadow: 1px 1px 3px beige;
  font-size: 24px;
}

.overlay-3D-model-menu-item span:hover {
  color: rgba(84, 1, 1, 0.8);
}

.overlay-3D-model-menu-item span:hover::after {
  position: absolute;
  content: attr(data-slottext);
  width: max-content;
  max-width: 14em;
  line-height: 1.25em;
  text-align: center;
  text-transform: none;
  text-shadow: none;
  font-weight: 600;
  font-size: 16px;
  top: -5.25em;
  left: -18em;
  padding: 0.75em;
  z-index: 2;
  color: beige;
  border-radius: 0.5em;
  border-bottom: 2px solid beige;
  border-right: 2px solid beige;
  background: rgba(0, 0, 0, 0.8);
}

.overlay-3D-model-menu-item:last-of-type {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.overlay-3D-model-menu-item:first-of-type {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.overlay-3D-model-menu-item:hover {
  background-color: rgba(0, 0, 0, 0.143);
}

.overlay-3D-info {
  --overlay-3D-info-border-width: 2px;
  position: absolute;
  top: 120px;
  right: 70px;
  line-height: 24px;
  text-transform: uppercase;
  padding: 25px 35px;
  border-radius: 15px;
  color: var(--accent-color);
  backdrop-filter: blur(5px);
  border: var(--overlay-3D-info-border-width) solid maroon;
  background-color: rgba(0, 0, 0, 0.03);
  cursor: pointer;
  opacity: var(--overlay-3D-put-behind);
}

.overlay-3D-info-btn-close {
  position: absolute;
  top: calc(var(--overlay-3D-info-border-width) * -1);
  right: calc(var(--overlay-3D-info-border-width) * -1);
  padding: 1px;
  border-radius: 50%;
  font-family: "Inter", sans-serif;
  font-size: 0.75rem;
  width: 32px;
  height: 32px;
  outline: none;
  border: none;
  background-color: transparent;
}

.overlay-3D-info-btn-close:hover {
  color: beige;
  cursor: pointer;
}

.overlay-3D-accent {
  margin-left: 8px;
  font-weight: 800;
  color: beige;
  text-shadow: 1px 1px 3px black;
}

.model-hotspot {
  --point-diameter: 6px;
  --annotation-left-offset: 35px;
  --annotation-top-offset: 20px;
  --annotation-line-left-offset: 0px;
  --min-hotspot-opacity: 0.5;

  display: block;
  width: var(--point-diameter);
  height: var(--point-diameter);
  border-radius: 50%;
  padding: 0;
  border: none;
  /* background-color: var(--accent-color); */
  /* background-color: rgb(19, 21, 85); */
  background-color: red;
  cursor: pointer;
}

.model-hotspot-fully-visible {
  --min-hotspot-opacity: 1;
}

.model-hotspot-dim {
  opacity: 0.1;
}

.model-hotspot-undim {
  opacity: 1;
}

.model-hotspot[data-hidden] {
  visibility: hidden;
}

.model-hotspot[data-visible] {
  visibility: visible;
}

.model-hotspot[slot="hotspot-model-HQ"] {
  background-color: beige;
}

/* .model-hotspot:not(.model-hotspot[slot="hotspot-model-Lounge"]) {
    background-color: green;
    opacity: 0.5;
    z-index: 0 !important;
} */

.model-hotspot-connector {
  position: absolute;
  top: -25px;
  left: calc(var(--point-diameter) + var(--annotation-line-left-offset));
  z-index: -1;
  pointer-events: none;
}

.model-hotspot-connector-2 {
  position: absolute;
  /* top: var(--overlay-3D-padding);
    left: var(--overlay-3D-padding); */
  top: 0;
  left: 0;
  pointer-events: none;
  opacity: var(--overlay-3D-put-behind);
}

.hotspot-annotation {
  --annotation-padding-sides: 12px;
  --model-hotspot-desc-visible: hidden;

  display: flex;
  flex-direction: row;
  transform: translate(
    var(--annotation-left-offset),
    var(--annotation-top-offset)
  );

  text-align: center;
  color: var(--accent-color);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  align-items: center;
  width: max-content;
  height: max-content;
}

.hotspot-annotation-info {
  background-color: beige;
  border-radius: 5px;
  width: fit-content;
}

/* .hotspot-annotation-info:has(.model-hotspot-ref-no-hover){
    clip-path: polygon(0% 0%,100% 0%,100% 87%,90% 100%,0% 100%);
} */

.hotspot-annotation-buttons {
  margin-left: var(--annotation-padding-sides);
  padding: 3px;
  background-color: transparent;
  font-size: 24px;
  font-weight: 400;
  color: beige;
  text-shadow: 1px 1px 2px black;
  cursor: default;
  align-self: baseline;
}

.hotspot-annotation-buttons:hover {
  /* background-color: rgba(245, 245, 220, 0.66); */
  /* backdrop-filter: blur(2px); */
  border-radius: 2px;
}

.hotspot-annotation-btn {
  /* backdrop-filter: blur(4px); */
  /* border-radius: 50%; */
  cursor: pointer;
}

.hotspot-annotation-btn:hover {
  color: white;
  text-shadow: 1px 1px 5px black;
}

/* TODO: chrome started to support pseudo element :has from 105 without tweaking */
/* .hotspot-annotation:has(.model-hotspot-ref) {
    padding: 0;
    padding-bottom: 10px;
} */

.model-hotspot-ref-caption {
  padding: 8px var(--annotation-padding-sides);
  padding-bottom: 12px;
  max-width: 100px;
  min-width: 100%;
  width: max-content;
}

.model-hotspot-ref,
.model-hotspot-ref-no-hover {
  --model-hotspot-ref-img-visible: hidden;
  display: block;
  padding: 5px var(--annotation-padding-sides);
  padding-bottom: 8px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  text-decoration: none;
  background-color: var(--accent-color);
  color: beige;
  text-transform: capitalize;
}

.model-hotspot-ref img {
  visibility: var(--model-hotspot-ref-img-visible);
  position: absolute;
  left: calc(
    var(--hotspot-hover-left) - var(--hotspot-hover-img-height) -
      var(--hotspot-hover-img-offset)
  );
  width: auto;
  height: var(--hotspot-hover-img-height);
  object-fit: contain;
  bottom: calc(var(--hotspot-hover-bottom) + 12px);
  /* padding: 3px; */
}

.model-hotspot-ref img,
.model-hotspot-ref:hover::before,
.model-hotspot-ref:hover::after {
  border-radius: 0.5rem;
  border-bottom: 2px solid beige;
  transform: perspective(100px) rotateY(var(--hotspot-ref-hover-angle));
  font-family: "Tillana", cursive;
  /* font-family: "Cagliostro", sans-serif; */
}

.model-hotspot-ref[border-left] img,
.model-hotspot-ref[border-left]:hover::before,
.model-hotspot-ref[border-left] :hover::after,
.model-hotspot-ref-hover[border-left] {
  border-left: 2px solid beige;
}

.model-hotspot-ref[border-right] img,
.model-hotspot-ref[border-right]:hover::before,
.model-hotspot-ref[border-right]:hover::after,
.model-hotspot-ref-hover[border-right] {
  border-right: 2px solid beige;
}

.model-hotspot-ref-hover {
  visibility: var(--model-hotspot-desc-visible);
  display: flex;
  position: absolute;

  top: var(--hotspot-hover-top);
  left: var(--hotspot-hover-left);
  width: var(--hotspot-hover-width);
  height: var(--hotspot-hover-height);

  font-family: "Tillana", cursive;
  text-align: center;
  border-radius: 0.5em;
  border-bottom: 2px solid beige;
  background: rgba(0, 0, 0, 0.9);
  color: beige;
  align-items: center;
  transform: perspective(200px) rotateY(var(--hotspot-ref-hover-angle));
}

.model-hotspot-ref-hover img {
  width: auto;
  height: 90%;
  object-fit: contain;
  float: left;
  padding: 2px;
  padding-right: 10px;
  border-right: 1px solid rgba(245, 245, 220, 0.4);
}

.model-hotspot-ref-hover span {
  text-align: center;
  width: 100%;
  font-size: 1rem;
  text-transform: none;
  padding: 5px;
  overflow-y: hidden;
  max-height: calc(var(--hotspot-hover-height) - 5px);
}

.model-hotspot-ref:hover {
  --model-hotspot-ref-img-visible: visible;
  color: white;
}

.model-hotspot-ref:hover::before,
.model-hotspot-ref:hover::after {
  content: attr(data-desc);
  position: absolute;
  width: max-content;
  max-width: 250px;
  max-height: var(--hotspot-hover-img-height);
  line-height: 1.25rem;
  bottom: var(--hotspot-hover-bottom);
  left: var(--hotspot-hover-left);
  overflow-y: hidden;
  padding: 0.75rem 1.1rem;
  font-weight: 400;
  text-align: center;
  text-transform: none;
}

.model-hotspot-ref:hover::before {
  z-index: 2;
  color: beige;
  background: rgba(0, 0, 0, 0.85);
}

.model-hotspot-ref:hover::after {
  color: transparent;
  background: linear-gradient(
    rgba(0, 0, 0, 0) 78px,
    rgba(0, 0, 0, 0.4) 92px,
    rgba(0, 0, 0, 1)
  );
  z-index: 3;
}

.model-hotspot-ref:has(img):hover::before,
.model-hotspot-ref:has(img):hover::after {
  box-sizing: border-box;
  height: 120px;
  bottom: calc(var(--hotspot-hover-bottom) + 20px);
}

.model-hotspot-ref-no-hover:hover {
  --model-hotspot-desc-visible: visible;
}

@media screen and (max-width: 1020px), (max-height: 500px) {
  .overlay-3D {
    display: none;
  }
}
