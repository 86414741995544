@import url(https://fonts.googleapis.com/css2?family=Caveat:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Codystar&family=Tillana&display=swap);
/* @import url("https://fonts.googleapis.com/css2?family=Play:wght@400;700&family=Merienda&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Advent+Pro:wght@300&family=Audiowide&family=Cabin&family=Faustina:wght@300&family=Kalam:wght@300;400&family=M+PLUS+Rounded+1c&family=Michroma&family=Tillana&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Nova+Script&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Cagliostro&display=swap"); */

body {
  --overlay-3D-offset: 58px;
  --overlay-3D-put-behind: none;
  --overlay-3D-bookmark-width: 80px;
  --hotspot-hover-width: 350px;
  --hotspot-hover-height: 125px;
  --hotspot-hover-left: -70px;
  --hotspot-hover-top: -150px;
  --hotspot-hover-bottom: 90px;
  --hotspot-hover-img-height: 108px;
  --hotspot-hover-img-offset: 8px;
  --hotspot-ref-hover-angle: 0deg;
}

.sides-not-present,
sides-present {
  background-repeat: no-repeat;
  background-size: cover;
}

.overlay-3D {
  --overlay-3D-padding: 2px;
  --overlay-3D-border-radius: 26px;
  --overlay-3D-color: linear-gradient(
    to bottom,
    rgb(244, 244, 230) 80%,
    rgb(203, 202, 192)
  );
  --overlay-3D-bookmark-height: 109%;
  --overlay-3D-menu-perspective: none;

  position: fixed;
  top: var(--overlay-3D-offset);
  left: var(--overlay-3D-offset);
  bottom: var(--overlay-3D-offset);
  right: var(--overlay-3D-offset);

  background: var(--overlay-3D-color);
  z-index: 10;
  border-radius: var(--overlay-3D-border-radius);
  /* box-shadow: 2px 5px 10px gray; */
  /* backdrop-filter: blur(10px); */
  -webkit-user-select: none; /* turn off text selection  / Webkit */ /* Firefox */
  -ms-user-select: none; /* IE 10  */
  user-select: none;
}

.overlay-3D:focus {
  outline: none;
}

.overlay-3D[fullscreen="1"] {
  --overlay-3D-offset: 0px;
  --overlay-3D-border-radius: 0px;
}

.overlay-3D[fullscreen="1"] .overlay-3D-bookmark-shadow,
.overlay-3D[fullscreen="1"] .overlay-3D-bookmark {
  visibility: hidden;
}

.overlay-3D[fullscreen="1"] .overlay-3D-gyro {
  left: 15px;
}

.overlay-3D[postervisible="1"] {
  --overlay-3D-padding: 0;
}

/* ---DARK THEME--- */
.overlay-3D[theme="dark"],
.overlay-3D[theme="dark"] .overlay-3D-poster {
  background-image: linear-gradient(
    to top,
    rgb(17, 12, 12) 70%,
    rgb(97, 86, 86)
  );
}

.overlay-3D[theme="dark"] .overlay-3D-caption {
  color: beige;
  text-shadow: 1px 1px 3px var(--accent-color);
}

.overlay-3D[theme="dark"] .overlay-3D-info {
  color: rgb(245, 220, 245);
}

/* .overlay-3D[theme="dark"] .overlay-3D-poster {
    background-color: rgb(27, 22, 22);
} */

.overlay-3D[theme="dark"] .overlay-3D-model-menu-item span {
  /* color: var(--accent-color); */
  color: rgb(231, 115, 115);
  text-shadow: none;
  font-size: 24px;
}

.overlay-3D[theme="dark"] .overlay-3D-model-menu-item span:hover {
  color: rgb(245, 240, 240);
}

.overlay-3D[theme="dark"] .overlay-3D-caption-toggle-sign {
  -webkit-filter: opacity(0.4);
          filter: opacity(0.4);
}

.overlay-3D[theme="dark"] .overlay-3D-info-btn-close {
  color: beige;
}

.overlay-3D[theme="dark"] .overlay-3D-info-btn-close:hover {
  color: white;
}

.overlay-3D[theme="dark"] .overlay-3D-model-menu-item:hover {
  background-color: rgba(245, 245, 220, 0.1);
}

.overlay-3D[theme="dark"] .model-hotspot-ref-hover {
  --model-hotspot-ref-hover-border-col: rgb(212, 203, 213);
  border-bottom: 2px solid var(--model-hotspot-ref-hover-border-col);
  background: rgba(245, 245, 220, 0.9);
  color: var(--accent-color);
}

/* .overlay-3D[theme="dark"] .model-hotspot-ref-hover span {
  position: relative;
} */

.overlay-3D[theme="dark"] .model-hotspot-ref-hover span:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    rgba(245, 245, 220, 0) 90px,
    rgba(245, 245, 220, 0.8) 108px,
    rgba(245, 245, 220, 0.9)
  );
  border-radius: 5px;
}

.overlay-3D[theme="dark"] .model-hotspot-ref-hover[border-right] {
  border-right: 2px solid var(--model-hotspot-ref-hover-border-col);
}

.overlay-3D[theme="dark"] .model-hotspot-ref-hover[border-left] {
  border-left: 2px solid var(--model-hotspot-ref-hover-border-col);
}

.overlay-3D[theme="dark"] .overlay-3D-animated-background {
  display: none;
}

.overlay-3D[theme="dark"] .overlay-3D-buttons-panel {
  opacity: 0.3;
}

.overlay-3D[theme="dark"] .overlay-3D-buttons-panel:hover {
  opacity: 1;
}

.overlay-3D-gyro {
  --overlay-3D-gyro-size: 120px;
  position: absolute;
  top: 15px;
  left: 95px;
  width: var(--overlay-3D-gyro-size);
  height: var(--overlay-3D-gyro-size);
  /* background: rgba(0, 0, 0, 0.01); */
  border-radius: 50%;
  background: radial-gradient(
    farthest-corner at 63px 62px,
    transparent 50%,
    rgba(0, 0, 0, 0.07) 70%,
    rgba(0, 0, 0, 0.03)
  );
  z-index: 4;
  /* opacity: var(--overlay-3D-put-behind); */
  opacity: 0.2;
  /* transform: translateZ(1px); */
  transition: opacity 350ms;
}

.overlay-3D-gyro[bringup="1"] {
  opacity: min(var(--overlay-3D-put-behind), 1);
}

.overlay-3D-gyro:hover {
  opacity: min(var(--overlay-3D-put-behind), 1);
}

.overlay-3D-gyro > canvas {
  width: var(--overlay-3D-gyro-size);
  height: var(--overlay-3D-gyro-size);
}

.overlay-3D-canvas {
  --overlay-3D-canvas-width: 135px;
  --overlay-3D-canvas-height: 120px;

  position: absolute;
  bottom: 8px;
  right: 8px;
  width: var(--overlay-3D-canvas-width);
  height: var(--overlay-3D-canas-height);
  pointer-events: auto;
  /* background-color: rgba(255, 0, 0, 0.136); */
}

.overlay-3D-canvas:hover:after {
  content: "Pick random";
  position: absolute;
  right: -16px;
  bottom: -16px;
  font-family: Inter, sans-serif;
  width: -webkit-max-content;
  width: max-content;
  line-height: 1.25em;
  text-align: center;
  text-transform: none;
  font-weight: 600;
  font-size: 16px;
  padding: 0.35em 0.75em;
  z-index: 2;
  color: beige;
  border-radius: 0.5em;
  border-bottom: 2px solid beige;
  border-right: 2px solid beige;
  background: rgba(0, 0, 0, 0.8);
}

.overlay-3D-bookmark-shadow {
  position: absolute;
  top: -2px;
  left: 1px;
  font-family: "Inter", sans-serif;
  width: var(--overlay-3D-bookmark-width);
  height: var(--overlay-3D-bookmark-height);
  background-color: rgba(0, 0, 0, 0.217);
  border-top-left-radius: var(--overlay-3D-border-radius);
  -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 51% 93%, 0% 100%);
          clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 51% 93%, 0% 100%);
  z-index: 1;
}

.overlay-3D-bookmark {
  --strips-color: rgba(70, 14, 70, 0.817);
  --band-color: rgba(240, 238, 219, 0.97);
  position: absolute;
  display: flex;
  top: -3px;
  left: 0px;
  width: var(--overlay-3D-bookmark-width);
  height: var(--overlay-3D-bookmark-height);
  min-height: 450px;
  background-image: linear-gradient(
    to right,
    var(--band-color) 65%,
    var(--strips-color) 65%,
    var(--strips-color) 73%,
    var(--band-color) 73%,
    var(--band-color) 80%,
    var(--strips-color) 80%,
    var(--strips-color) 88%,
    var(--band-color) 88%
  );
  border-top-left-radius: var(--overlay-3D-border-radius);
  -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 51% 93%, 0% 100%);
          clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 51% 93%, 0% 100%);

  font-size: 24px;
  text-transform: uppercase;
  font-family: "Inter", sans-serif;
  font-family: "Codystar", cursive;
  font-weight: 400;
  -webkit-writing-mode: vertical-lr;
      -ms-writing-mode: tb-lr;
          writing-mode: vertical-lr;
  -webkit-text-orientation: upright;
          text-orientation: upright;
  text-align: start;
  color: var(--accent-color);
  z-index: 2;
}

.overlay-3D-menu-icon {
  position: relative;
  font-size: 24px;
  width: 52px;
  height: 72px;
  text-align: center;
  -webkit-writing-mode: horizontal-tb;
      -ms-writing-mode: lr-tb;
          writing-mode: horizontal-tb;
  margin-top: 12px;
  margin-left: 3px;
}

.overlay-3D-bookmark-item {
  --overlay-3D-padding-top: 0px;
  position: relative;
  width: 52px;
}

.overlay-3D-bookmark-item:first-of-type {
  --overlay-3D-padding-top: 28px;
}

.overlay-3D-sidemenu-icon {
  position: absolute;
  top: -28px;
  right: 6px;
  width: 32px;
  height: 32px;
}

.overlay-3D-sidemenu-icon-dim {
  -webkit-filter: opacity(0.15);
          filter: opacity(0.15);
}

.overlay-3D-sidemenu-icon-dim:first-of-type {
  margin-top: 68px;
}

.overlay-3D-sidemenu-icon-dim:hover {
  -webkit-filter: none;
          filter: none;
}

.overlay-3D-sidemenu-icon:hover {
  cursor: pointer;
}

.overlay-3D-sidemenu-caption {
  display: block;
  text-decoration: none;
  padding-top: var(--overlay-3D-padding-top);
  padding-bottom: 28px;
  margin-left: 14px;
  color: var(--accent-color);
  height: -webkit-max-content;
  height: max-content;
  border-top-left-radius: var(--overlay-3D-border-radius);
  -webkit-clip-path: polygon(0% 8%, 40% 2%, 100% 0%, 100% 80%, 50% 84%, 0% 88%);
          clip-path: polygon(0% 8%, 40% 2%, 100% 0%, 100% 80%, 50% 84%, 0% 88%);
}

.overlay-3D-sidemenu-caption:hover {
  text-shadow: 1px 1px 2px var(--accent-color);
  /* background-color: red; */
  cursor: pointer;
}

.overlay-3D-sidemenu-item {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  pointer-events: none;
}

.overlay-3D model-viewer {
  /* --poster-color: transparent; */
  --poster-color: var(--overlay-3D-color);
  --progress-bar-color: transparent;

  width: 100%;
  height: 100%;
  border-radius: var(--overlay-3D-border-radius);
  padding: var(--overlay-3D-padding);
}

.overlay-3D-animated-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.overlay-3D-btn-close {
  position: absolute;
  color: maroon;
  top: -65px;
  right: -35px;
  font-size: 52px;
  -webkit-filter: opacity(0.55);
          filter: opacity(0.55);
  text-shadow: 1px 1px 2px white;
  width: 60px;
  height: 60px;
  text-align: center;
  vertical-align: middle;
  border-radius: 50%;
  line-height: 60px;
}

.overlay-3D-btn-close:hover {
  background-color: beige;
  -webkit-background-clip: text;
          background-clip: text;
  text-shadow: none;
  color: transparent;
  -webkit-filter: none;
          filter: none;
  cursor: pointer;
}

.overlay-3D-progress-bar {
  --mv-progress-bar-width: 260px;
  --mv-progress-bar-height: 3.75rem;

  width: 100%;
  height: 100%;
  text-align: center;
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
}

.overlay-3D-update-bar {
  position: absolute;
  color: beige;
  width: var(--mv-progress-bar-width);
  top: calc(50% - var(--mv-progress-bar-height) / 2);
  left: calc(50% - var(--mv-progress-bar-width) / 2);
  font-size: 2.5rem;
  text-shadow: 1px 1px 2px black;
}

.overlay-3D-update-bar-percent {
  position: absolute;
  bottom: 1.25rem;
  left: calc(1.35rem + var(--mv-progress-bar-width) / 2);
  font-size: 1.25rem;
}

.overlay-3D-progress-bar-background {
  position: absolute;
  display: flex;
  align-items: center;
  top: calc(50% - var(--mv-progress-bar-height) / 2 + 6.25rem);
  left: calc(50% - var(--mv-progress-bar-width) / 2);
  background-color: rgba(255, 255, 255, 0.7);
  height: 4px;
  border-radius: 16px;
  width: var(--mv-progress-bar-width);
}

.overlay-3D-progress-bar-foreground {
  width: 0px;
  height: 2px;
  margin: 0 5px;
  background-color: var(--accent-color);
}

.overlay-3D-poster {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: scale-down;
  background: var(--overlay-3D-color);
  border-radius: var(--overlay-3D-border-radius);
}

.overlay-3D-caption {
  position: absolute;
  top: 38px;
  right: 60px;
  font-family: "Caveat", sans-serif;
  font-size: 24px;
  letter-spacing: 3px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 5px 15px;
  border-radius: 15px;
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  cursor: pointer;
  text-align: right;
  opacity: var(--overlay-3D-put-behind);
  -webkit-transform: var(--overlay-3D-menu-perspective);
          transform: var(--overlay-3D-menu-perspective);

  background: linear-gradient(
    to bottom,
    var(--accent-color) 38%,
    rgb(240, 217, 239)
  );

  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;

  /* transition: transform 300ms; */
}

.overlay-3D-caption:hover {
  color: var(--accent-color);
  text-shadow: 1px 1px 2px beige;
}

/* .overlay-3D-caption[do-perspective="1"]:hover {
  transform: perspective(950px) translateZ(24px) rotateY(-3deg);
} */

.overlay-3D-caption-toggle-sign {
  position: absolute;
  top: 14px;
  right: -16px;
  -webkit-filter: opacity(0.2);
          filter: opacity(0.2);
  color: var(--accent-color);
}

.overlay-3D-caption-toggle-sign-up {
  top: -6px;
}

.overlay-3D-caption-items-num {
  position: absolute;
  top: 3px;
  right: -36px;
  width: 18px;
  height: 18px;
  font-family: "Inter", sans-serif;
  font-size: 10px;
  letter-spacing: 0px;
  font-weight: 400;
  line-height: 18px;
  border-radius: 50%;
  text-align: center;
  background-color: rgba(8, 0, 255, 0.333);
  color: white;
  text-shadow: none;
  /* border: 1px solid red; */
}

.overlay-3D-caption-items-num[updatedfortnight="true"] {
  background-color: rgba(255, 0, 0, 0.333);
}

.overlay-3D-buttons-panel {
  position: absolute;
  right: 0px;
  top: calc((100vh - var(--overlay-3D-offset) * 2) / 2 - 100px);
  width: 50px;
  height: 200px;
  padding: 5px;
  opacity: 0.1;
}

.overlay-3D-buttons-panel:hover {
  opacity: 1;
}

.overlay-3D-buttons-panel-btn {
  position: relative;
  width: 42px;
  height: 42px;
  outline: none;
  border: none;
  margin: 2px 0px;
  color: beige;
  background-color: var(--accent-color);
  /* background-color: darkgray; */
  border-radius: 6px;
  cursor: pointer;
}

.overlay-3D-buttons-panel-btn:hover::after {
  content: attr(data-tooltip);
  position: absolute;
  top: -0.5em;
  left: -200px;
  font-family: Inter, sans-serif;
  width: -webkit-max-content;
  width: max-content;
  max-width: 190px;
  line-height: 1.25em;
  text-align: center;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 16px;
  padding: 1em 1em;
  z-index: 2;
  color: beige;
  border-radius: 0.5em;
  border-bottom: 2px solid beige;
  border-right: 2px solid beige;
  background: rgba(0, 0, 0, 0.8);
  visibility: hidden;
  -webkit-animation: revealtooltip 3000ms linear 1000ms;
          animation: revealtooltip 3000ms linear 1000ms;
}

@-webkit-keyframes revealtooltip {
  0% {
    visibility: visible;
    opacity: 0;
  }
  8% {
    opacity: 1;
  }
  92% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes revealtooltip {
  0% {
    visibility: visible;
    opacity: 0;
  }
  8% {
    opacity: 1;
  }
  92% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}
/* @keyframes revealtooltip {
  0% {
    visibility: visible;
  }
  100% {
    visibility: hidden;
  }
} */

.overlay-3D-buttons-panel-btn[toggled-off="true"] {
  border: 4px double beige;
}

.overlay-3D-buttons-panel-btn img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.overlay-3D-models-menu {
  padding: 12px 0;
  margin-top: 12px;
  font-family: Lora, serif;

  /* font-family: "Roboto Serif", serif; */
  font-size: 28px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 1px;
  text-shadow: 1px 1px 2px var(--accent-color);
  text-transform: none;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  border-top: 7px double maroon;
  min-width: 270px;
}

.overlay-3D-model-menu-item {
  --overlay-3D-show-model-preview: none;
  padding: 8px 12px;
  color: beige;
  cursor: pointer;
}

.overlay-3D-model-menu-item:hover {
  --overlay-3D-show-model-preview: initial;
}

.overlay-3D-model-menu-item-hover {
  --model-hover-padding: 10px;
  --model-hover-height: 360px;

  display: var(--overlay-3D-show-model-preview);
  position: absolute;
  bottom: 20px;
  left: calc(-1 * var(--model-hover-height) * 1.333 - 80px);
  height: var(--model-hover-height);
  -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);

  background: linear-gradient(
    to bottom,
    rgba(141, 141, 141, 0.9) 8px,
    rgba(102, 101, 101, 0.8) 16px,
    rgba(0, 0, 0, 0.75) 32px,
    rgba(0, 0, 0, 0.73)
  );
  box-shadow: 4px 4px 20px -2px rgba(0, 0, 0, 0.8);
  border-radius: 26px;
  padding: var(--model-hover-padding);
  border-top: 1px solid beige;
  border-left: 3px solid beige;
  -webkit-animation: model_preview_fadein 400ms ease-in 1,
    gentle-move-model-preview 9000ms linear infinite;
          animation: model_preview_fadein 400ms ease-in 1,
    gentle-move-model-preview 9000ms linear infinite;
}

@-webkit-keyframes model_preview_fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes model_preview_fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes gentle-move-model-preview {
  0% {
    -webkit-transform: perspective(250px) translateZ(-10px) rotateY(8deg);
            transform: perspective(250px) translateZ(-10px) rotateY(8deg);
  }
  30% {
    -webkit-transform: perspective(230px) translateZ(-10px) rotateY(5deg);
            transform: perspective(230px) translateZ(-10px) rotateY(5deg);
  }
  60% {
    -webkit-transform: perspective(260px) translateZ(-10px) rotateY(9deg);
            transform: perspective(260px) translateZ(-10px) rotateY(9deg);
  }
  100% {
    -webkit-transform: perspective(255px) translateZ(-10px) rotateY(8deg);
            transform: perspective(255px) translateZ(-10px) rotateY(8deg);
  }
}

@keyframes gentle-move-model-preview {
  0% {
    -webkit-transform: perspective(250px) translateZ(-10px) rotateY(8deg);
            transform: perspective(250px) translateZ(-10px) rotateY(8deg);
  }
  30% {
    -webkit-transform: perspective(230px) translateZ(-10px) rotateY(5deg);
            transform: perspective(230px) translateZ(-10px) rotateY(5deg);
  }
  60% {
    -webkit-transform: perspective(260px) translateZ(-10px) rotateY(9deg);
            transform: perspective(260px) translateZ(-10px) rotateY(9deg);
  }
  100% {
    -webkit-transform: perspective(255px) translateZ(-10px) rotateY(8deg);
            transform: perspective(255px) translateZ(-10px) rotateY(8deg);
  }
}

.overlay-3D-model-menu-item-hover img {
  --model-hover-img-height: calc(
    var(--model-hover-height) - 2 * var(--model-hover-padding)
  );

  height: var(--model-hover-img-height);
  width: calc(var(--model-hover-img-height) * 1.333);
  object-fit: contain;
}

.overlay-3D-model-menu-item-hover:after {
  content: "annotations: " attr(data-annotations);
  position: absolute;
  top: 0;
  right: 32px;
  font-family: "Caveat", sans-serif;
  font-size: 32px;
}

.overlay-3D-model-menu-item span {
  position: relative;
  margin-right: 6px;
  /* color: var(--accent-color); */
  color: rgba(84, 1, 1, 0.593);
  text-shadow: 1px 1px 3px beige;
  font-size: 24px;
}

.overlay-3D-model-menu-item span:hover {
  color: rgba(84, 1, 1, 0.8);
}

.overlay-3D-model-menu-item span:hover::after {
  position: absolute;
  content: attr(data-slottext);
  width: -webkit-max-content;
  width: max-content;
  max-width: 14em;
  line-height: 1.25em;
  text-align: center;
  text-transform: none;
  text-shadow: none;
  font-weight: 600;
  font-size: 16px;
  top: -5.25em;
  left: -18em;
  padding: 0.75em;
  z-index: 2;
  color: beige;
  border-radius: 0.5em;
  border-bottom: 2px solid beige;
  border-right: 2px solid beige;
  background: rgba(0, 0, 0, 0.8);
}

.overlay-3D-model-menu-item:last-of-type {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.overlay-3D-model-menu-item:first-of-type {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.overlay-3D-model-menu-item:hover {
  background-color: rgba(0, 0, 0, 0.143);
}

.overlay-3D-info {
  --overlay-3D-info-border-width: 2px;
  position: absolute;
  top: 120px;
  right: 70px;
  line-height: 24px;
  text-transform: uppercase;
  padding: 25px 35px;
  border-radius: 15px;
  color: var(--accent-color);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  border: var(--overlay-3D-info-border-width) solid maroon;
  background-color: rgba(0, 0, 0, 0.03);
  cursor: pointer;
  opacity: var(--overlay-3D-put-behind);
}

.overlay-3D-info-btn-close {
  position: absolute;
  top: calc(var(--overlay-3D-info-border-width) * -1);
  right: calc(var(--overlay-3D-info-border-width) * -1);
  padding: 1px;
  border-radius: 50%;
  font-family: "Inter", sans-serif;
  font-size: 0.75rem;
  width: 32px;
  height: 32px;
  outline: none;
  border: none;
  background-color: transparent;
}

.overlay-3D-info-btn-close:hover {
  color: beige;
  cursor: pointer;
}

.overlay-3D-accent {
  margin-left: 8px;
  font-weight: 800;
  color: beige;
  text-shadow: 1px 1px 3px black;
}

.model-hotspot {
  --point-diameter: 6px;
  --annotation-left-offset: 35px;
  --annotation-top-offset: 20px;
  --annotation-line-left-offset: 0px;
  --min-hotspot-opacity: 0.5;

  display: block;
  width: var(--point-diameter);
  height: var(--point-diameter);
  border-radius: 50%;
  padding: 0;
  border: none;
  /* background-color: var(--accent-color); */
  /* background-color: rgb(19, 21, 85); */
  background-color: red;
  cursor: pointer;
}

.model-hotspot-fully-visible {
  --min-hotspot-opacity: 1;
}

.model-hotspot-dim {
  opacity: 0.1;
}

.model-hotspot-undim {
  opacity: 1;
}

.model-hotspot[data-hidden] {
  visibility: hidden;
}

.model-hotspot[data-visible] {
  visibility: visible;
}

.model-hotspot[slot="hotspot-model-HQ"] {
  background-color: beige;
}

/* .model-hotspot:not(.model-hotspot[slot="hotspot-model-Lounge"]) {
    background-color: green;
    opacity: 0.5;
    z-index: 0 !important;
} */

.model-hotspot-connector {
  position: absolute;
  top: -25px;
  left: calc(var(--point-diameter) + var(--annotation-line-left-offset));
  z-index: -1;
  pointer-events: none;
}

.model-hotspot-connector-2 {
  position: absolute;
  /* top: var(--overlay-3D-padding);
    left: var(--overlay-3D-padding); */
  top: 0;
  left: 0;
  pointer-events: none;
  opacity: var(--overlay-3D-put-behind);
}

.hotspot-annotation {
  --annotation-padding-sides: 12px;
  --model-hotspot-desc-visible: hidden;

  display: flex;
  flex-direction: row;
  -webkit-transform: translate(
    var(--annotation-left-offset),
    var(--annotation-top-offset)
  );
          transform: translate(
    var(--annotation-left-offset),
    var(--annotation-top-offset)
  );

  text-align: center;
  color: var(--accent-color);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  align-items: center;
  width: -webkit-max-content;
  width: max-content;
  height: -webkit-max-content;
  height: max-content;
}

.hotspot-annotation-info {
  background-color: beige;
  border-radius: 5px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

/* .hotspot-annotation-info:has(.model-hotspot-ref-no-hover){
    clip-path: polygon(0% 0%,100% 0%,100% 87%,90% 100%,0% 100%);
} */

.hotspot-annotation-buttons {
  margin-left: var(--annotation-padding-sides);
  padding: 3px;
  background-color: transparent;
  font-size: 24px;
  font-weight: 400;
  color: beige;
  text-shadow: 1px 1px 2px black;
  cursor: default;
  align-self: baseline;
}

.hotspot-annotation-buttons:hover {
  /* background-color: rgba(245, 245, 220, 0.66); */
  /* backdrop-filter: blur(2px); */
  border-radius: 2px;
}

.hotspot-annotation-btn {
  /* backdrop-filter: blur(4px); */
  /* border-radius: 50%; */
  cursor: pointer;
}

.hotspot-annotation-btn:hover {
  color: white;
  text-shadow: 1px 1px 5px black;
}

/* TODO: chrome started to support pseudo element :has from 105 without tweaking */
/* .hotspot-annotation:has(.model-hotspot-ref) {
    padding: 0;
    padding-bottom: 10px;
} */

.model-hotspot-ref-caption {
  padding: 8px var(--annotation-padding-sides);
  padding-bottom: 12px;
  max-width: 100px;
  min-width: 100%;
  width: -webkit-max-content;
  width: max-content;
}

.model-hotspot-ref,
.model-hotspot-ref-no-hover {
  --model-hotspot-ref-img-visible: hidden;
  display: block;
  padding: 5px var(--annotation-padding-sides);
  padding-bottom: 8px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  text-decoration: none;
  background-color: var(--accent-color);
  color: beige;
  text-transform: capitalize;
}

.model-hotspot-ref img {
  visibility: var(--model-hotspot-ref-img-visible);
  position: absolute;
  left: calc(
    var(--hotspot-hover-left) - var(--hotspot-hover-img-height) -
      var(--hotspot-hover-img-offset)
  );
  width: auto;
  height: var(--hotspot-hover-img-height);
  object-fit: contain;
  bottom: calc(var(--hotspot-hover-bottom) + 12px);
  /* padding: 3px; */
}

.model-hotspot-ref img,
.model-hotspot-ref:hover::before,
.model-hotspot-ref:hover::after {
  border-radius: 0.5rem;
  border-bottom: 2px solid beige;
  -webkit-transform: perspective(100px) rotateY(var(--hotspot-ref-hover-angle));
          transform: perspective(100px) rotateY(var(--hotspot-ref-hover-angle));
  font-family: "Tillana", cursive;
  /* font-family: "Cagliostro", sans-serif; */
}

.model-hotspot-ref[border-left] img,
.model-hotspot-ref[border-left]:hover::before,
.model-hotspot-ref[border-left] :hover::after,
.model-hotspot-ref-hover[border-left] {
  border-left: 2px solid beige;
}

.model-hotspot-ref[border-right] img,
.model-hotspot-ref[border-right]:hover::before,
.model-hotspot-ref[border-right]:hover::after,
.model-hotspot-ref-hover[border-right] {
  border-right: 2px solid beige;
}

.model-hotspot-ref-hover {
  visibility: var(--model-hotspot-desc-visible);
  display: flex;
  position: absolute;

  top: var(--hotspot-hover-top);
  left: var(--hotspot-hover-left);
  width: var(--hotspot-hover-width);
  height: var(--hotspot-hover-height);

  font-family: "Tillana", cursive;
  text-align: center;
  border-radius: 0.5em;
  border-bottom: 2px solid beige;
  background: rgba(0, 0, 0, 0.9);
  color: beige;
  align-items: center;
  -webkit-transform: perspective(200px) rotateY(var(--hotspot-ref-hover-angle));
          transform: perspective(200px) rotateY(var(--hotspot-ref-hover-angle));
}

.model-hotspot-ref-hover img {
  width: auto;
  height: 90%;
  object-fit: contain;
  float: left;
  padding: 2px;
  padding-right: 10px;
  border-right: 1px solid rgba(245, 245, 220, 0.4);
}

.model-hotspot-ref-hover span {
  text-align: center;
  width: 100%;
  font-size: 1rem;
  text-transform: none;
  padding: 5px;
  overflow-y: hidden;
  max-height: calc(var(--hotspot-hover-height) - 5px);
}

.model-hotspot-ref:hover {
  --model-hotspot-ref-img-visible: visible;
  color: white;
}

.model-hotspot-ref:hover::before,
.model-hotspot-ref:hover::after {
  content: attr(data-desc);
  position: absolute;
  width: -webkit-max-content;
  width: max-content;
  max-width: 250px;
  max-height: var(--hotspot-hover-img-height);
  line-height: 1.25rem;
  bottom: var(--hotspot-hover-bottom);
  left: var(--hotspot-hover-left);
  overflow-y: hidden;
  padding: 0.75rem 1.1rem;
  font-weight: 400;
  text-align: center;
  text-transform: none;
}

.model-hotspot-ref:hover::before {
  z-index: 2;
  color: beige;
  background: rgba(0, 0, 0, 0.85);
}

.model-hotspot-ref:hover::after {
  color: transparent;
  background: linear-gradient(
    rgba(0, 0, 0, 0) 78px,
    rgba(0, 0, 0, 0.4) 92px,
    rgba(0, 0, 0, 1)
  );
  z-index: 3;
}

.model-hotspot-ref:has(img):hover::before,
.model-hotspot-ref:has(img):hover::after {
  box-sizing: border-box;
  height: 120px;
  bottom: calc(var(--hotspot-hover-bottom) + 20px);
}

.model-hotspot-ref-no-hover:hover {
  --model-hotspot-desc-visible: visible;
}

@media screen and (max-width: 1020px), (max-height: 500px) {
  .overlay-3D {
    display: none;
  }
}

:root {
  --footer-offset: 0px;
  --icon-invert-value: 0%;
  --context-menu-marker-left: 0.75rem;
  --context-menu-marker-border-offset: 3px;

  --header-height: 160px;
  /* banner*/
  --banner-height: 133px;
  --banner-mask-color: rgba(165, 154, 134, 0.767);
  /* --banner-background: url("../assets/images/banner6.jpg"); */

  /* sidebar */
  --sidebar-width: 220px;
  --sidebar-highlight-color: rgba(44, 35, 30, 0.7);

  /* reader */
  --reader-width: 55%;
  --reader-left-margin: 2em;
  --reader-left-margin-wo-sidebar: 10em;

  /* ifobar */
  --infobar-width: max(calc(35% - var(--sidebar-width)), 285px);
  --infobar-block-color: rgba(192, 175, 135, 0.164);

  /* footer */
  --footer-height: 195px;
  --footer-background-color: rgba(29, 20, 2, 0.45);
  /* --footer-background-color: rgba(32, 32, 30, 0.75); */
  --footer-offset: 0px;

  --highlight-color: rgb(53, 102, 70);
  --nav-text-color: rgb(19, 19, 17);
  /* --background-color: rgb(179, 171, 134); */
  --background-color: rgb(185, 174, 158);
  --drawing-mask: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.3));
  --margin-from-main: 2.5rem;
  --accent-color: #44285c;
  --post-content-margin: 2rem;
  --login-overlay-width: 40vw;

  --side-width: 0px;
  --scroll-bar-width: 0px;
  --recommended-width: 1440px;

  --back-to-top-button-right: 5rem;
  --back-to-top-button-bottom: 3rem;
  --side-panels-opacity: 0.85;

  --clock-color: rgba(132, 138, 123, 0.76);

  --post-heading-font: "Inter", sans-serif;
}

body {
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  font-weight: 300;
  min-width: 100vw;
  min-height: 110vh;
  overflow-y: scroll;
  /* background-color: rgb(165, 155, 143); */
}

audio::-webkit-media-controls-panel {
  background-color: rgb(63, 52, 63);
}

.not-found-img {
  display: block;
  text-align: center;
  margin: 3rem auto;
  width: 80%;
  -webkit-filter: grayscale(0.5);
          filter: grayscale(0.5);
  border-radius: 8px;
}

.svg-animation-container {
  width: 0;
  height: 0;
}

.back-to-top-button {
  flex-direction: column;
  display: none;
  position: fixed;
  bottom: 3rem;
  bottom: var(--back-to-top-button-bottom);
  right: calc(0px + 5rem);
  right: calc(var(--side-width) + var(--back-to-top-button-right));
  margin: 0.5em 0;
  border-radius: 50%;
  background: linear-gradient(
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0.6),
    rgba(0, 0, 0, 0.35)
  );
  border: none;
  outline: none;
  height: 60px;
  width: 60px;
  align-items: center;
  z-index: 21;
}

.back-to-top-button > img {
  margin-top: 2px;
}

.back-to-top-button:hover {
  box-shadow: -1px -2px 1px rgba(248, 248, 242, 0.85);
  cursor: pointer;
}

.back-to-top-button:hover img {
  -webkit-transform: translate(0px, -3px);
          transform: translate(0px, -3px);
}

.back-to-top-img {
  --icon-dimentions: 3.25rem;
  width: var(--icon-dimentions);
  height: var(--icon-dimentions);
}

/* load spinner */

.load-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  font-family: Inter, sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 6px;
  color: #44285c;
  color: var(--accent-color);

  -webkit-backdrop-filter: blur(4px);

          backdrop-filter: blur(4px);
  background-color: rgba(0, 0, 0, 0.09);
  /* border-radius: var(--overlay-3D-border-radius); */
}

.load-spinner-graph {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.load-spinner h2 {
  text-align: center;
  font-size: 16px;
}

/* ************************************************* */
/* main section */
/* ************************************************* */
.social {
  font-family: Inter, sans-serif;
  display: grid;
  grid-template-columns: repeat(3, 81px);
  grid-gap: 2px;
  gap: 2px;
  justify-content: flex-start;
  margin-left: 2rem;
  margin-left: var(--post-content-margin);
  margin-top: 1.75rem;
  -webkit-filter: sepia(0.9);
          filter: sepia(0.9);
}
.social:hover {
  -webkit-filter: sepia(0.1);
          filter: sepia(0.1);
  transition: 200ms;
}

.main-container {
  display: flex;
  width: 100%;
  margin: 0px;
  margin-top: 2.5rem;
  margin-top: var(--margin-from-main);
}
.blur-background {
  -webkit-filter: blur(0px);
          filter: blur(0px);
}

.icon-sidebar-item {
  --icon-dimentions: 1.25rem;
  vertical-align: middle;
  width: var(--icon-dimentions);
  height: var(--icon-dimentions);
}

.elem-visible-hidden {
  visibility: hidden;
}

.elem-visible-show {
  visibility: visible;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
  .banner-buttons,
  .collection-buttons,
  .icon-sidebar-item,
  .back-to-top-img,
  .footer-social-button,
  .drawing-img {
    image-rendering: -webkit-optimize-contrast !important;
  }
}
/* Unset for Safari 11+ */
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    .banner-buttons,
    .collection-buttons,
    .icon-sidebar-item,
    .back-to-top-img,
    .footer-social-button,
    .drawing-img {
      image-rendering: unset !important;
    }
  }
}

.sidebar {
  font-family: "Inter", sans-serif;
  font-weight: bold;
  font-size: 0.75rem;
  -webkit-filter: opacity(0.85);
          filter: opacity(0.85);
  -webkit-filter: opacity(var(--side-panels-opacity));
          filter: opacity(var(--side-panels-opacity));
  transition: -webkit-filter 300ms;
  transition: filter 300ms;
  transition: filter 300ms, -webkit-filter 300ms;
}

/* .sidebar-filler {
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(36, 24, 2, 0) 36px,
    rgba(242, 245, 226, 0.5) 39px,
    rgba(242, 245, 226, 0.5) 130px,
    rgba(0, 0, 0, 0) 131px
  );
  height: 100%;
  width: 150px;
} */

.sidebar:hover {
  -webkit-filter: none;
          filter: none;
}

.side-nav {
  height: 100%;
  width: 220px;
  width: var(--sidebar-width);
  display: flex;
  flex-direction: column;
}

.side-nav .close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 2em;
  outline: none;
  border: none;
  background: transparent;
  display: none;
  color: beige;
}

/* sidebar links */
.sidebar a {
  font-weight: 500;
  color: rgb(32, 23, 12);
  display: block;
  padding: 0.6em;
  text-decoration: none;
  border-top-right-radius: 0.725em;
  border-bottom-right-radius: 0.725em;
  border-left: rgba(0, 0, 0, 0) 3px solid;
  text-transform: uppercase;
  letter-spacing: 0.15em;
}

.sidebar a:hover {
  --icon-invert-value: 90%;
}

.sidebar a:hover:not(.active) {
  color: blanchedalmond;
  background-color: rgb(53, 102, 70);
  background-color: var(--highlight-color);
  border-left: rgba(44, 35, 30, 0.7) 3px solid;
  border-left: var(--sidebar-highlight-color) 3px solid;
  transition: background 300ms;
}

.sidebar a:hover:not(.active) i {
  color: blanchedalmond;
}

.sidebar .active {
  --icon-invert-value: 90%;
  background-color: black;
  background-color: rgba(44, 35, 30, 0.7);
  background-color: var(--sidebar-highlight-color);
  color: blanchedalmond;
  border-left: rgb(53, 102, 70) 3px solid;
  border-left: var(--highlight-color) 3px solid;
}
/* .sidebar i {
  width: 1.5rem;
} */
.sidebar-menu-item {
  /* width: 1.5rem; */
  -webkit-filter: invert(0%);
          filter: invert(0%);
  -webkit-filter: invert(var(--icon-invert-value));
          filter: invert(var(--icon-invert-value));
  margin-right: 0.8rem;
}

/* .sidebar i::after {
  content: "";
  padding-right: 0.8rem;
} */

.sidebar a:not(.active) i:not(.sidebar-new-content) {
  color: #44285c;
  color: var(--accent-color);
}

.sidebar-new-content {
  position: relative;
  /* color: rgba(0, 0, 0, 0.5); */
  font-size: 1.2em;
  width: 1rem;
}
/* .sidebar .active .sidebar-new-content {
  color: rgba(255, 235, 205, 0.75);
  filter: invert(var(--icon-invert-value));
} */

.sidebar-new-content:hover::after {
  position: absolute;
  content: "Content updated within previous fortnight";
  width: 14em;
  line-height: 1.25em;
  text-align: center;
  text-transform: none;
  font-weight: 600;
  top: -3.75em;
  left: 1em;
  padding: 0.75em;
  z-index: 2;
  color: beige;
  border-radius: 0.5em;
  border-bottom: 2px solid beige;
  border-right: 2px solid beige;
  background: rgba(0, 0, 0, 0.8);
  -webkit-filter: none;
          filter: none;
}

.context-menu {
  margin-top: 5em;
  margin-left: 1.25em;
  margin-bottom: 2em;
  position: -webkit-sticky;
  position: sticky;
  top: 9rem;
  /* overflow is not compatible with sticky */
}

.context-menu-collapsed,.filter-panel-collapsed {
  height: 2em;
  overflow-y: hidden;
}

.context-menu i,.filter-panel i {
  margin-left: 1em;
}

.context-menu h3,.filter-panel h3 {
  color: #44285c;
  color: var(--accent-color);
  margin-bottom: 0.5em;
  text-transform: uppercase;
  cursor: pointer;
}

.context-menu a {
  padding: 0.25em 0.5em;
  margin-top: 0.75em;
  margin-left: 1em;
}

.context-menu .left-marker-beige {
  border-left: 1px solid beige;
  padding-left: calc(
    0.75rem + 3px);
  padding-left: calc(
    var(--context-menu-marker-left) + var(--context-menu-marker-border-offset)
  );
}

.context-menu .left-marker-accent {
  border-left: 1px solid maroon;
  padding-left: calc(
    0.75rem + 3px);
  padding-left: calc(
    var(--context-menu-marker-left) + var(--context-menu-marker-border-offset)
  );
}

.context-menu .left-marker-active {
  border-left: calc(3px + 1px) solid beige;
  border-left: calc(var(--context-menu-marker-border-offset) + 1px) solid beige;
  padding-left: 0.75rem;
  padding-left: var(--context-menu-marker-left);
}

.context-menu a::first-letter {
  font-size: 1.25em;
  font-weight: 700;
  color: #44285c;
  color: var(--accent-color);
}

.context-menu a:hover {
  color: black !important;
  background-color: transparent !important;
  border-left: none !important;
  border-bottom: 1px solid maroon;
  margin-bottom: -1px;
  border-radius: 0;
  padding-left: calc(0.75rem + 5px);
  padding-left: calc(var(--context-menu-marker-left) + 5px);
}

.filter-panel {
  margin-top: 6em;
  margin-left: 1.25em;
  margin-bottom: 1em;
  position: -webkit-sticky;
  position: sticky;
  top: 3rem;
  /* overflow is not compatible with sticky */
}

.filter-tag{
  display: inline-block;
  margin-top: 1rem;
  margin-left: 1.75rem;
  cursor: pointer;
}

.filter-tag:hover::after{
content: "\0353" !important;
/* padding: 0 2px; */
/* margin-left: 8px; */
/* color: white; */
/* font-size: 24px; */
font-weight:100 ;
  /* top: -32px;
  right: -20px; */
}

.menu-overlay {
  position: fixed;
  /* top: calc(var(--header-height)); */
  /* top: calc(var(--header-height) - var(--pageY-current-offset)); */
  top: 160px;
  top: var(--header-height);
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0.04),
    rgba(0, 0, 0, 0)
  );
  z-index: 2;
}

/* **************** */
/* reader */
/* **************** */

.reader {
  border: 1px solid maroon;
  box-shadow: 1px 1px 10px darkgray;
  width: 55%;
  width: var(--reader-width);
  padding: 0em 2em;
  padding-bottom: 1em;
  border-radius: 1em;
  border-top-right-radius: 3rem;
  margin: 0em;
  min-height: calc(100vh - 160px - 195px);
  min-height: calc(100vh - var(--header-height) - var(--footer-height));
  margin-bottom: 8em;
  margin-left: 2em;
  margin-left: var(--reader-left-margin);
}
.chapter-name {
  font-family: "Inter", sans-serif;
  margin: 0.7em 0;
  color: rgb(43, 9, 88);
  text-align: center;
  text-transform: uppercase;
}

.chapter-unpublished {
  background: rgba(175, 172, 172, 0.767);
}
.reader-hr {
  border: 1px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(
    to right,
    rgba(177, 172, 158, 0.2),
    rgba(53, 102, 70, 1),
    rgba(177, 172, 158, 0.2)
  );
  border-radius: 2px;
  width: 10%;
  margin-top: 2.5em;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2.5em;
}

.reader h3 {
  font-family: "Inter", sans-serif;
  color: #44285c;
  color: var(--accent-color);
  margin: 1em 1em;
  text-align: left;
}
.reader h4 {
  margin-left: 3em;
}
.reader address {
  margin: 1em 1em;
}
.chapter-text {
  font-family: "Texturina", serif;
  text-align: justify;
  text-justify: inter-word;
}
.chapter-text-paragraph {
  text-indent: 1.125em;
}
.chapter-text-paragraph::first-letter {
  font-size: 1.5rem;
  font-weight: bolder;
}
.picture-frame {
  display: grid;
  /* border: 1px solid black; */
}
.picture:hover,
.picture-slide:hover {
  cursor: zoom-in;
}
.picture {
  /* float: left; */
  justify-self: center;
  width: 12rem;
  border-radius: 0.35em;
  border: 1px solid rgb(117, 101, 80);
  /* padding-right: 0.7125em;
  padding-top: 1.125em; */
  padding: 0.125em;
  margin: 1em;
}
.picture-slide {
  justify-self: center;
}
.picture-left {
  float: left;
  margin-left: 0;
}
.picture-right {
  float: right;
  margin-right: 0;
}

.sticky-embelishment {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0px;
  bottom: var(--footer-offset);
  align-self: flex-start;
  margin-top: -14em;
  pointer-events: none;
  z-index: -1;
}

.item-loading {
  color: beige;
  /* line-height: 50vh; */
  -webkit-animation: item-rotate 2.5s linear infinite;
          animation: item-rotate 2.5s linear infinite;
}

@-webkit-keyframes item-rotate {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes item-rotate {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.twitter-button {
  background: #1da1f2;
  color: white;
  font-size: 11px;
  font-weight: 400;
  text-decoration: none;
  text-align: center;
  padding: 3px 0;
  border-radius: 3px;
  min-width: 81px;
  width: auto;
  height: 20px;
}

.twitter-button > i {
  margin-right: 0.5em;
}

/* footer */

.footer {
  /* width: 100vw; */
  height: 195px;
  height: var(--footer-height);
  text-align: center;
  /* background: var(--background-footer-color); */
  background: linear-gradient(
      to bottom,
      rgba(29, 20, 2, 0.45),
      rgba(29, 20, 2, 0.45)),
    url(/static/media/shanty-town-029_l.ceb39d86.webp),
    50% url(/static/media/shanty-town-006.a90d4725.webp),
    100% url(/static/media/shanty-town-029.8184179e.webp);
  background: linear-gradient(
      to bottom,
      var(--footer-background-color),
      var(--footer-background-color)
    ),
    url(/static/media/shanty-town-029_l.ceb39d86.webp),
    50% url(/static/media/shanty-town-006.a90d4725.webp),
    100% url(/static/media/shanty-town-029.8184179e.webp);
  background-repeat: no-repeat;
  background-size: contain;
  /* background-position: 100%; */
  color: black;
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 360px) and (max-width: 810px) and (orientation: landscape) {
  .sidebar {
    width: 0;
  }
  .sidebar-new-content:hover::after {
    position: fixed;
    left: 0.5em;
    top: 1em;

    width: 30%;
    max-width: 180px;
    line-height: 1em;
    font-weight: 400;
  }
  .side-nav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 22;
    top: 0;
    left: 0;
    background-color: rgba(27, 27, 26, 0.92);
    overflow-x: hidden;
    padding-top: 60px;
    transition: 500ms;
  }
  .side-nav a {
    color: rgb(240, 234, 228);
    transition: 300ms;
  }
  .side-nav a:hover:not(.active) {
    color: blanchedalmond;
    transition: background 300ms;
  }
  .side-nav .active {
    background-color: rgb(124, 121, 121);
  }
  .side-nav .close-button {
    display: block;
    position: absolute;
    /* top: -25px;
    right: -10px; */
    font-size: 2.5em;
    transition: none;
  }
  .context-menu {
    position: unset;
  }
  .context-menu a::first-letter {
    color: rgb(124, 121, 121);
  }
  .context-menu a:hover {
    color: beige !important;
  }
  .close-button:hover,
  .close-button:focus {
    background-color: transparent !important;
    border: none !important ;
    color: rgb(124, 121, 121) !important;
  }
  .side-nav a:hover:is(.close-button),
  .side-nav a:focus:is(.close-button) {
    background-color: transparent;
    border: none;
    color: rgb(124, 121, 121);
  }
  .main-container {
    flex-direction: column;
    align-items: center;
  }
  .reader {
    --reader-width: 94%;
    margin: 0 auto;
    margin-bottom: 8em;
  }
  .reader img {
    float: left;
    width: 7rem;
  }

  .sticky-embelishment {
    display: none;
  }
}

@media screen and (min-width: 811px) and (max-width: 1200px) and (orientation: landscape) {
  .reader {
    --reader-width: calc(
      100% - var(--infobar-width)
        /* var(--reader-width) + 20rem
        + var(--sidebar-width) - 3em + var(--infobar-width) */
    );
    margin-left: 2em;
    margin-left: var(--reader-left-margin);
    margin-right: 2em;
  }
  .sticky-embelishment {
    display: none;
  }
}

@media screen and (min-width: 200px) and (max-width: 315px) and (orientation: portrait) {
  :root {
    --footer-height: 250px;
  }

  .picture {
    width: 10rem;
    margin: 1em;
  }
}

@media screen and (min-width: 200px) and (max-width: 340px) and (orientation: portrait) {
  /* reader */
  .picture {
    width: 10rem;
    margin: 1em;
  }
  .reader {
    --reader-left-margin-wo-sidebar: 0;
  }

  .footer {
    background: linear-gradient(
        to right,
        rgba(29, 20, 2, 0.45),
        rgba(29, 20, 2, 0.45)),
      url(/static/media/shanty-town-029-center.22f3d80e.webp);
    background: linear-gradient(
        to right,
        var(--footer-background-color),
        var(--footer-background-color)
      ),
      url(/static/media/shanty-town-029-center.22f3d80e.webp);
    background-repeat: no-repeat;
    background-size: cover;
  }
}

@media screen and (min-width: 341px) and (max-width: 1024px) and (orientation: portrait) {
  :root {
    --reader-width: 94%;
  }
  .picture {
    margin: 1em;
  }

  .footer {
    background: linear-gradient(
        to right,
        rgba(29, 20, 2, 0.45),
        rgba(29, 20, 2, 0.45)),
      url(/static/media/shanty-town-029_l.ceb39d86.webp),
      100% url(/static/media/shanty-town-029.8184179e.webp);
    background: linear-gradient(
        to right,
        var(--footer-background-color),
        var(--footer-background-color)
      ),
      url(/static/media/shanty-town-029_l.ceb39d86.webp),
      100% url(/static/media/shanty-town-029.8184179e.webp);
    background-repeat: no-repeat;
    background-size: contain;
  }
}

@media screen and (orientation: portrait) {
  .sidebar {
    width: 0%;
    font-size: 1rem;
    -webkit-filter: none;
            filter: none;
  }
  .sidebar-new-content:hover::after {
    position: fixed;
    left: 0.5em;
    top: 1em;

    width: 65%;
    line-height: 1em;
    font-weight: 400;
  }
  .side-nav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 22;
    top: 0;
    left: 0;
    background-color: rgba(27, 27, 26, 0.92);
    overflow-x: hidden;
    padding-top: 60px;
    transition: 500ms;
  }
  .side-nav a {
    color: rgb(240, 234, 228);
    transition: 300ms;
  }
  .side-nav a:hover:not(.active) {
    color: blanchedalmond;
    transition: background 300ms;
  }
  .side-nav .active {
    background-color: rgb(124, 121, 121);
    /* color: blanchedalmond; */
  }
  .side-nav .close-button {
    display: block;
    position: absolute;
    /* top: -25px;
    right: -10px; */
    font-size: 2.5em;
    transition: none;
  }
  .context-menu {
    position: unset;
  }
  .context-menu a::first-letter {
    color: rgb(124, 121, 121);
  }
  .context-menu a:hover {
    color: beige !important;
  }
  .close-button:hover,
  .close-button:focus {
    background-color: transparent !important;
    border: none !important ;
    color: rgb(124, 121, 121) !important;
  }
  .side-nav a:hover:is(.close-button),
  .side-nav a:focus:is(.close-button) {
    background-color: transparent;
    border: none;
    color: rgb(124, 121, 121);
  }

  .main-container {
    flex-direction: column;
    align-items: center;
  }

  .toggle-side-nav-button {
    margin-bottom: 0.2em;
  }

  .reader {
    --reader-width: 92%;
    margin-left: 0;
    border-top-right-radius: 1em;
  }
  .chapter-text {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .picture-frame {
    clear: both;
    float: none;
    justify-items: center;
  }
  .sticky-embelishment {
    display: none;
  }
}

